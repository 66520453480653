$color-primary-light: #ffe650;
$color-primary-dark: #b89c00;
$color-primary-darker: darken($color-primary-dark, 15%);

$color-secondary-light: #8b80f9;
$color-secondary-lighter: lighten($color-secondary-light, 15%);
$color-secondary-dark: #3a00b8;
$color-secondary-darker: darken($color-secondary-dark, 15%);

$color-transparent: rgba(255, 255, 255, 0);

$color-colored-section: $color-primary-light;

$color-separator: #c5c5c9;

$color-text: #657581;

.color-secondary-light { color: $color-secondary-light; }
.color-secondary-dark { color: $color-secondary-dark !important; }
