@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "colors";
@import "buttons";

body {
	background-color: #ffffff;
	font-size: 16px;
	font-family: Nunito, sans-serif;
	color: $color-text;
}

.colored {
	background-color: $color-colored-section;
}

h1 {
	font-size: 2.25rem;
	font-weight: bold;

	&.secondary {
		color: $color-secondary-dark;
	}
}

div.subtitle {
	color: $color-secondary-darker;
}

a.link {
	color: $color-secondary-dark;

	&:hover {
		text-decoration: underline !important;
		color: $color-secondary-darker;
	}

	&.underlined {
		text-decoration: underline;
	}

	&.no-underline {
		text-decoration: none;
	}
}

.tag {
	display: inline-block;
	padding: .25rem .75rem;
	font-size: .9rem;
	border-radius: 1rem;
	background-color: $color-secondary-lighter;
	color: $color-secondary-dark;
}

.clear-left {
	clear: left;
}

div.modal-title{
	color: $color-secondary-dark;
}
