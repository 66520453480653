@import "../styles/colors";

div.jump-to-top {
	a {
		display: block;
		width: 100%;
		height: 3.25rem;
		line-height: 3.25rem;
		background-color: $color-primary-dark;
		text-align: center;
		font-size: 2rem;
		color: #ffffff;
		transition: .5s;

		&:hover {
			background-color: $color-primary-darker;
		}
	}
}

footer {
	background-color: #ffffff;
	font-size: 18px;

	ul.social-networks {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			display: inline-block;
			margin: 0 1em 0 0;
			padding: 0;
			list-style-type: none;

			&:last-child {
				margin-right: 0;
			}

			a {
				display: block;
				text-align: center;
				font-size: 15px;
				color: $color-text;

				&:hover {
					filter: brightness(25%);
				}
			}
		}
	}

	div.contacts {
		color: $color-secondary-dark;
	}

	div.copyright {
		border-top: 1px solid $color-separator;
	}
}
