@import "~bootstrap/scss/bootstrap-utilities";
@import "../commons/styles/colors";

div#landing-page {
	section h1 {
		margin: 0 0 3rem !important;
		padding: 0 !important;
		line-height: 3rem;
		text-align: center;
		font-size: 2rem;
		color: #15354b;
	}

	section#intro{
		background-image: url('../assets/images/background-stripes.svg');
		background-repeat: no-repeat;
		background-position: top right;
	}

	section div.ribbon {
		position: absolute;
		z-index: 1000;
		top: -20px;
		left: -75px;
		width: 50px;
		height: 180px;
		background-image: url("../assets/images/ribbon.png");
		background-repeat: no-repeat;

		.ribbon-text {
			margin-top: 20px;
			width: 30px;
			height: 140px;
			line-height: 30px;
			font-size: 1rem;
			text-transform: uppercase;
			writing-mode: vertical-lr;
			text-align: center;
			color: #ffffff;
			transform: rotate(180deg);
		}
	}

	main div.container {
		padding: 4rem 0;
	}

	section#intro {
		div.container {
			padding-bottom: 4rem;
		}

		img.background {
			visibility: hidden;
		}

		div.background-container {
			position: relative;
		}

		div.background {
			position: absolute;
			width: 100%;
			height: 100%;
			background-image: url('../assets/images/background.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: top center;
		}
	}

	section#how-to {
		div.container {
			position: relative;
		}

		.step {
			@include media-breakpoint-up(md) {
				&.top {
					min-height: 17rem;
				}
			}

			@include media-breakpoint-up(xl) {
				&.top {
					min-height: 23rem;
				}
			}

			@include media-breakpoint-up(xxl) {
				&.top {
					min-height: 20rem;
				}
			}

			h2 {
				display: inline-block;
				width: 3rem;
				height: 3rem;
				line-height: 3rem;
				border-radius: 1.5rem;
				background-color: $color-primary-light;
				font-size: 1.75rem;
				font-weight: bold;
				text-align: center;
				color: $color-primary-dark;
			}

			h3 {
				margin-top: 1rem;
				color: $color-secondary-light;
			}

			.description {
				margin-top: 1rem;
				line-height: 1.75rem;
				font-size: 1rem;
			}
		}

		.art {
			text-align: center;
		}
	}

	section#organizations {
		div.organization-list {
			.image img {
				border-radius: .75rem;
			}

			h2 {
				margin: 1.25rem 0 .7rem 0;
				font-size: 1.25rem;
				font-weight: bold;
				color: #000000;
			}

			.description {
				line-height: 1.7rem;
				font-size: .9rem;
			}
		}

		a.btn {
			margin: 3rem 0;
		}
	}

	section#about-us {
		h2 {
			margin-bottom: 3rem;
			font-size: 1.75rem;
			color: $color-secondary-dark;
		}

		.photo img {
			border-radius: 64px;
		}

		div.name {
			font-size: 1.5rem;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	&.landing-page-public {
		section div.ribbon {
			left: -25px !important;
		}

		section#intro {
			div.container {
				padding-bottom: 0 !important;
			}

			div.background {
				background-image: url('../assets/images/background-public.svg') !important;
			}

			img.logo {
				width: 35%;
				height: 35%;
			}

			@include media-breakpoint-up(md) {
				img.logo {
					margin-top: 4%;
					width: 55%;
					height: 55%;
				}
			}
		}

		section#how-to {
			div.separator {
				margin: 2.75rem auto;
				width: 50%;
				height: 1px;
				background: $color-secondary-dark;
			}
		}

		section#about-us {
			div.history {
				border-top-right-radius: 150px;
				border-bottom-right-radius: 150px;
				background-color: white;
			}
		}
	}
}
