@import "../styles/colors";

@mixin nav-default-link {
	padding: 0 2rem !important;
	font-size: 1.05rem;
	color: $color-secondary-dark !important;

	&:hover {
		color: darken($color-secondary-dark, 15%) !important;
	}
}

nav {
	.navbar-brand img {
		margin-top: -10px;
		margin-right: 1rem;
	}

	a.nav-link {
		@include nav-default-link;
	}

	.btn {
		margin: auto 0;

		&.btn-login {
			@include nav-default-link;
			border-color: transparent;
			background-color: transparent;

			&:focus, &:active {
				box-shadow: none !important;
			}
		}

		&.btn-registration {
			padding: .7em 2em;
			border-color: transparent;
			font-size: 1.05rem;
			font-weight: normal;
		}
	}

	.dropdown {
		& > a {
			padding: .5rem !important;
			border: 2px solid $color-primary-dark;
			border-radius: 3rem;
		}

		a:hover, a:focus, a:active {
			background-color: lighten($color-primary-light, 10%);
		}
	}
}
