@import "~bootstrap/scss/bootstrap";
@import "colors";

.btn-mch-rounded {
	border-radius: 3rem;
}

.btn-mch-primary {
	@include button-variant(
		$background: $color-primary-light,
		$border: $color-primary-light,
		$color: $color-secondary-dark,
	);

	:disabled{
		$background: $color-primary-dark;
	}

	font-weight: bold;
}

.btn-mch-secondary {
	@include button-variant(
		$background: $color-secondary-dark,
		$border: $color-secondary-dark,
		$color: #ffffff,
		$hover-background: $color-secondary-darker,
		$hover-border: $color-secondary-darker,
	);

	:disabled{
		$background: $color-secondary-light;
	}

	font-weight: bold;
}

.btn-mch-light{
	@include button-variant(
		$background: #ffffff,
		$border: $color-secondary-light,
		$color: $color-secondary-light,
		$hover-background: #ffffff,
		$hover-border: $color-secondary-light,
		$hover-color: $color-secondary-light,
		$active-background: #ffffff,
		$active-border: $color-secondary-light,
		$active-color: $color-secondary-light,
	);
}
