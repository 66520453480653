@import "./colors";

div.form-style{
	.form-control{
		border-radius: 2rem;
	}
	
	.form-label {
		margin-bottom: 0.5rem;
		color: $color-primary-dark;
	}

	.dnd{
		background-color: #ffffff;
		color: $color-text;
		height: 5em;
		border-radius: 2rem;
	}

	.dnd-files{
		color: $color-primary-dark;
	}

	div.spinner-border{
		color: $color-primary-dark;
	}

	div.form-check label.form-check-label{
		color: $color-primary-dark;
	}

	span.submit-info{
		color: $color-secondary-dark;
	}

	span.submit-error{
		color: red;
	}

	.link-alike{
		cursor: pointer;
		:hover{
			text-decoration: underline;
		}
	}
}
